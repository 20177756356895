import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

import background2 from "_assets/img/graphics/a.svg";

const ContactsWrapper = styled.div`
  margin: 2rem 9.25rem;
  display: flex;
  justify-content: center;
  ${screens.lg} {
    margin: 0 auto;
  }

  .contacts-container {
    display: flex;
    max-width: 1920px;
    width: 100%;
    gap: 5rem;
  }

  .left-container,
  .right-container {
    flex: 1;
  }

  .left-container {
    text-align: center;
    ${screens.lg} {
      display: none;
    }
  }

  .right-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;

    .social-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    h1 {
      color: ${colors.primary_2};
      text-transform: uppercase;
      font-style: italic;
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.25rem;
      color: ${colors.primary_2};
      font-style: italic;
    }

    .input-container {
      display: flex;
      gap: 1rem;
      margin: 1rem 0;
      input {
        /* background: ${({ theme }) => theme.text}; */
        color: ${({ theme }) => theme.text_2};
        padding: 0.5rem;
        font-size: medium;
        border: none;
        border-bottom: 2px solid ${colors.light_border};
        width: 100%;
        padding-left: 1rem;
        transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
          border-radius: 2px;
          outline: 2px solid #87bff2;
          box-shadow: 0 0 0 0.25rem #0f7fe540;
        }
      }
      input::placeholder,
      textarea::placeholder {
        color: inherit;
      }

      input.error::placeholder,
      textarea.error::placeholder {
        color: red;
      }
    }

    .textarea-container {
      display: flex;
      max-width: 100%;

      textarea {
        background-image: url(${background2});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        resize: vertical;
        min-height: 5rem;
        max-height: 8rem;
        overflow: auto;
        padding: 0.5rem 1rem;
        font-size: medium;
        border: none;
        border-bottom: 2px solid ${colors.light_border};
        border-left: 2px solid ${colors.light_border};
        width: 100%;
        transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        /* background: ${({ theme }) => theme.text}; */

        &:focus {
          border-radius: 2px;
          outline: 2px solid #87bff2;
          box-shadow: 0 0 0 0.25rem #0f7fe540;
          color: ${({ theme }) => theme.text_2};
        }
      }
      ::placeholder {
        opacity: 0.5;
        color: ${({ theme }) => theme.text_2};
      }
    }

    .button-container {
      text-align: end;
    }
  }
`;

export { ContactsWrapper };
