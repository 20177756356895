import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const CalculatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: ${({ theme }) => theme.background_emphasis};
  ::placeholder {
    text-transform: uppercase;
    text-align: center;
    color: ${colors.text_muted};
  }

  ${screens.md} {
    flex-direction: column;
    height: auto;
  }

  input {
    max-width: 14rem;
    background: inherit;
  }

  Button {
  }
`;

const CalculatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 0 2rem;
  max-width: 1920px;

  ${screens.lg} {
    width: 100%;
    padding: 0.5rem;
  }

  h3 {
    text-transform: uppercase;
    font-style: italic;
    ${screens.lg} {
      font-size: 12px;
    }
  }

  .input-group {
    display: flex;
    gap: 1rem;

    Button {
      height: auto;
      padding: 0 1rem;
    }

    ${screens.lg} {
      input {
        width: 8rem;
      }
    }
  }
`;

export { CalculatorWrapper, CalculatorContainer };
