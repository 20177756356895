import React from "react";
import { LogoContainer } from "./logo.style";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <LogoContainer>
      <Link to="/" className="logo">
        <img
          className="brand"
          src={require("_assets/img/logowb.png")}
          alt="Médicos na Manga Logo"
        ></img>
      </Link>
    </LogoContainer>
  );
};

export default Logo;
