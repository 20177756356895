import styled from "styled-components";
import { colors } from "_theme/colors";
import background from "_assets/img/company-numbers/background.png";

export const CompanyNumbersWrapper = styled.div`
  display: flex;
  background-image: url(${background});
  background-size: fill;
  height: 13rem;

  border: 2px solid red;
`;

export const CompanyNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  border: 2px solid blue;

  ul{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 6.5rem;

    li{
      h2{

        color: ${colors.primary_2};
      }
    }
    .item{
      display: flex;
      flex-direction: column;
   
    }
  }
`;
