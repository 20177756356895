import { Outlet, useLocation } from "react-router-dom";

import Header from "../../header/header";
import { UserLayoutWrapper } from "./user-layout.style";
import Footer from "_components/organisms/footer/footer";
import { TopPage } from "_components/atoms";
import { useEffect } from "react";

const UserLayout = () => {

  const location = useLocation();

  useEffect(() => {
    // Scroll to top on location change
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <UserLayoutWrapper>
      
      <Header />
      <Outlet />
      <Footer />
    </UserLayoutWrapper>
  );
};

export default UserLayout;
