import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Container, HomeContainer} from "./home.style";
import Carousel from "_components/molecules/carousel/carousel";
import About from "_pages/user/about/about";
import { Footer } from "_components/organisms";
import { Calculator, CompanyNumbers, DicasMenu, Mapa, Mappic } from "_components/molecules";
import HomeMenu from "_components/molecules/home-menu/home-menu";
import map from "_assets/img/map/mapa.jpg";

const Welcome = () => {
   const { t } = useTranslation();
  return (
    <HomeContainer>
       <Helmet>
        <meta name="description" content="Your meta description here" />
        <title>{t("Helmet.title")} | {t("Home.title")}</title>
      </Helmet>
      <Carousel />
      {/* <Calculator /> */}
      <Container>
        {/* <CompanyNumbers /> */}
        <About />
        {/* <HomeMenu /> */}
        <DicasMenu />
      </Container>
      {/* <MapContainer>
        <Mapa/>
        
      </MapContainer> */}
    </HomeContainer>
  );
};

export default Welcome;
