import React from "react";
import { useTranslation } from "react-i18next";
import {
  AboutWrapper,
  Container1,
  Container2,
  Container3,
} from "./about.style";
import question from "_assets/img/graphics/stetos-question-mark.svg";
// import whyUs from "_assets/img/graphics/stetos-jacket.svg";
import whyUs from "_assets/img/graphics/Frame.svg";
import star from "_assets/img/graphics/stetos-star.svg";
import { ReactComponent as Bullet } from "_assets/img/graphics/Frame.svg";

import { useEffect } from "react";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

import logo from "_assets/img/logo medicos na manga.svg";

const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation();
  return (
    <AboutWrapper>
      {/* <div className="about-banner">
        <h1>Sobre Nós</h1>
      </div> */}
      <div className="top-container">
        <div className="container-left" data-aos="fade-up-right">
          <img src={logo} alt="Médicos na Manga" />
        </div>
        <div className="container-right" data-aos="fade-up-left">
          {/* <img src={question} alt="We are" /> */}
          <h1>{t("About.about_us")}</h1>
          <p>
            <strong>{t("About.about_us_text_strong")}</strong>
            {t("About.about_us_text")}
          </p>
          <p>
            <strong>{t("About.about_us_text_strong_2")}</strong>
          </p>
          <p>
            <strong>{t("About.about_us_text_strong_3")}</strong>
            {t("About.about_us_text_2")}
            <strong>{t("About.about_us_text_strong_4")}</strong>
          </p>
        </div>
      </div>
      <div className="middle-container" /*data-aos="fade-in"*/>
        <div className="middle-content">
          <div className="text-container">
            <p data-aos="slide-up">{t("About.about_us_text_middle")}</p>
          </div>
          {/* <h1 data-aos="slide-up">{t("About.why_us")}</h1> */}
        </div>
        <div className="img">
          <img src={whyUs} alt="Why us?" data-aos="slide-down" />
        </div>
      </div>
      <div className="bottom-container">
        <div className="bottom-content">
          {/* <img src={star} alt="Why us?" data-aos="slide-down" /> */}
          <h1 data-aos="slide-up">{t("About.differ_us")}</h1>
        </div>
        <div className="text-container">
          <div className="text-content" data-aos="fade-up" data-aos-delay="200">
            <h2 data-aos="fade-up">{t("About.differ_us_title_1")}</h2>
            <p data-aos="fade-up">{t("About.differ_us_text_1")}</p>
          </div>
          <div className="text-content" data-aos="fade-up" data-aos-delay="400">
            <h2 data-aos="fade-up">{t("About.differ_us_title_2")}</h2>
            <p data-aos="slide-up">{t("About.differ_us_text_2")}</p>
          </div>
          <div className="text-content" data-aos="fade-up" data-aos-delay="600">
            <h2 data-aos="fade-up">{t("About.differ_us_title_3")}</h2>
            <p data-aos="fade-up">{t("About.differ_us_text_3")}</p>
          </div>
        </div>
      </div>
      <Container1>
        <div className="text-container1">
          <div className="text-max1">
            <h1 data-aos="fade-right" data-aos-delay="200">
              {t("About.compromise_title")}
            </h1>
            <p data-aos="fade-left" data-aos-delay="200">
              <strong> {t("About.compromise_text")}</strong>
            </p>
          </div>
        </div>
        <div className="text-container2">
          <div className="text-max2">
            <h1 data-aos="fade-right" data-aos-delay="200">
              {t("About.services_title")}
            </h1>
            <p data-aos="fade-left" data-aos-delay="200">
              <strong> {t("About.services_text_1")}</strong>
            </p>

            <p data-aos="fade-left" data-aos-delay="200">
              {t("About.services_text_2")}
            </p>
          </div>
        </div>
      </Container1>
      <Container2>
        <div className="content-container img"></div>
        <div className="content-container">
          <div className="text-max2">
            <h1>{t("About.objectives_title")}</h1>
            <ul>
              <li data-aos="fade-left" data-aos-delay="150">
                <div className="icon">
                  <Bullet className="icon" />
                </div>

                <p>{t("About.objectives_text_1")}</p>
              </li>
              <li data-aos="fade-left" data-aos-delay="300">
                <div className="icon">
                  <Bullet className="icon" />
                </div>
                <p>{t("About.objectives_text_2")}</p>
              </li>
              <li data-aos="fade-left" data-aos-delay="450">
                <div className="icon">
                  <Bullet className="icon" />
                </div>
                <p>
                {t("About.objectives_text_3")}
                </p>
              </li>
              <li data-aos="fade-left" data-aos-delay="600">
                <div className="icon">
                  <Bullet className="icon" />
                </div>
                <p>{t("About.objectives_text_4")}</p>
              </li>
            </ul>
          </div>
        </div>
      </Container2>
      <Container3>
        <div className="text-container">
          <p data-aos="fade-up" data-aos-delay="150">
          {t("About.final_text_1")}<strong>{t("About.final_text_strong_1")}</strong>{t("About.final_text_2")}<strong>{t("About.final_text_strong_2")}</strong>{" "}
          {t("About.final_text_3")}<strong>{t("About.final_text_strong_3")}</strong>
          </p>
        </div>
      </Container3>
    </AboutWrapper>
  );
};

export default About;
