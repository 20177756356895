import { screens } from "_theme/screens";
import styled from "styled-components";

const HomeContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4.5rem;

  ${screens.lg} {
gap: 0;
    }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1920px;
  margin: 0 auto;
  /* margin: 1.5rem auto; */
`;

export { HomeContainer, Container };
