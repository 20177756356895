import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const Menu = styled.div`
  display: flex;
  text-transform: uppercase;
  gap: 2rem;
  font-size: 14px;

  ${screens.md} {

    }

  .nav-link {
    transition: all 0.2s;

    &:hover {
      color: ${colors.primary};
    }
    
  }
.nav-link.active {
      color: ${colors.primary_2};
    }
  ${screens.md} {
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
    /* display: none; */
  }
`;

export { Menu };
