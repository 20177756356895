import { screens } from "_theme/screens";
import styled from "styled-components";

export const DicasMenuWrapper = styled.div`
display: flex;
flex-direction: column;
  margin: 4.5rem 0;
  max-width: 1920px;
  .title {
    padding: 0.5rem 2rem;
    font-style: italic;
    text-transform: uppercase;

  }
`;

export const DicasMenuContainer = styled.div`
  display: flex;
  justify-content: center; // Isso distribui espaço igualmente entre os itens
  align-items: center; // Isso centraliza os itens verticalmente
  /* height: 10.25rem; */
  gap: 5rem;

  ${screens.lg} {
height: auto;
gap: .5rem;
    }
`;

export const MenuItem = styled.div`
/* width: 100%; */
/* max-height: 100%; */
  /* flex-grow: 1; */
  // Define a altura das divs
  /* background-color: lightgrey; // Apenas para visualização, você pode mudar conforme necessário */

  a {
    /* position: absolute; */
    /* display: flex;
    padding: 2rem 1.5rem; */
  }
  img {
    height: 100%;
    width: 100%;
  }
`;
