// top-page.js
import React, { useState, useEffect } from 'react';
import { TopButton } from './top-page.styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const TopPage = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) { // Ajuste o valor para um número menor
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <TopButton onClick={handleBackToTop} show={showTopBtn} >
        <div className='top-arrow'>
              <FontAwesomeIcon icon={faArrowUp} />
        </div>
          </TopButton>
  );
};

export default TopPage;