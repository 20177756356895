import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const ContactListWrapper = styled.div`
  .contact-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    ${screens.sm} {
      /* width: 100%; */
    }

    .frame {
      display: flex;
      align-items: center;
      svg {
        path {
          stroke: ${({ theme }) => theme.icon};
        }
      }
    }

    .contact-link {
      ${screens.ssm} {
        width: 100%;
      }
      :hover {
        svg g {
          path {
            fill: ${colors.primary_2};
          }
        }
      }

      svg {
        path {
          fill: ${({ theme }) => theme.icon};
        }
      }

      .phone,
      .email {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        margin-left: 1rem;
      }
      .phone {
        margin-bottom: 0.25rem;
      }
    }
  }
`;

export { ContactListWrapper };
