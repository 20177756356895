import styled from "styled-components";

const LogoContainer = styled.div`
  display: flex;
  height:100%;

  .logo{
    display: flex;
    height: 100%;
  }
`;
export { LogoContainer };
