import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  ${screens.ssm} {
      /* justify-content: center; */

    }

  .icon {
    width: 1.5rem;
    height: 1.5rem;

    path{
      fill: ${({ theme }) => theme.icon};
      }

      &:hover{
        path{
          fill:   ${colors.primary_2};
        }
    }
  }
`;
export { LinkWrapper };
