import React from "react";
import { TermsWrapper } from "./terms.style";
import { Helmet } from "react-helmet";

const Terms = () => {
  return (
    <TermsWrapper>
      <Helmet>
        <meta name="description" content="Your meta description here" />
        <title>Médicos na Manga | Termos e Condições de Utilização</title>
      </Helmet>
        <div className="terms-banner"></div>
      <div className="terms-container">
        <h1>TERMOS E CONDIÇÕES DE UTILIZAÇÃO</h1>

        <div className="terms">
          <ol>
            <li>
              <h2> Introdução</h2>
              <ol>
                <li>
                  O presente documento contém as regras aplicáveis à utilização
                  do website Médicos na Manga, disponível em www.________ , as
                  quais, no seu conjunto, constituem os Termos e Condições
                  Gerais de Utilização do Website (doravante Termos e
                  Condições). Ao aceder ao Website, o respetivo utilizador
                  (doravante “Utilizador”) concorda em respeitar e cumprir com
                  os presentes Termos e Condições. Por favor, leia estes Termos
                  e Condições atentamente antes de utilizar o Website. Na
                  eventualidade de não tencionar ficar subordinado a estes
                  Termos e Condições, dever-se-á abster de utilizar o Website;
                  em algumas circunstâncias, a não-aceitação expressa dos Termos
                  e Condições poderá inviabilizar a navegação no Site.
                </li>
                <li>
                  O Site é administrado pela Médicos na Manga (Mínima & Arte –
                  Lda.), NIPC 516875221 em Rua Pinto Bessa nº584, 6º Dirito,
                  4300-428 Porto (doravante “Empresa”)
                </li>
                <li>
                  Os dados de contacto da Empresa são: Email: Correio Postal:
                </li>
                <li>
                  A Empresa reserva-se no direito de alterar qualquer parte do
                  conteúdo do Website, em qualquer momento, a seu critério
                  exclusivo e sem aviso prévio.
                </li>
              </ol>
            </li>
            <li>
              <h2>Privacidade</h2>
              <ol>
                <li>
                  Os dados pessoais dos Utilizadores que sejam recolhidos pela
                  Empresa serão tratados no estrito respeito e cumprimento da
                  legislação aplicável.
                </li>
                <li>
                  Pretendemos que os Utilizadores conheçam as regras gerais de
                  privacidade e tratamento de dados que recolhemos e tratamos no
                  âmbito da disponibilização do Website. Fazem parte integrante
                  destes Termos e Condições a Política de Privacidade do Website
                  (doravante “Política de Privacidade”), a qual inclui as regras
                  aplicáveis à utilização de cookies e mecanismos de
                  armazenamento e acesso a informações. Por favor, leia a
                  Política de Privacidade atentamente antes de utilizar o
                  Website.
                </li>
              </ol>
            </li>
            <li>
              <h2>Uso do website</h2>
              <ol>
                <li>
                  O Utilizador é o responsável exclusivo pela utilização do
                  Website e do uso que dele faça, incluindo da informação nele
                  contida.
                </li>
                <li>
                  É expressamente proibida a utilização do Website para fins
                  ilícitos ou contrários à lei, bem como para fins comerciais ou
                  de negócio.
                </li>
                <li>
                  O Utilizador não poderá interferir, de qualquer forma legal ou
                  ilegal, com o conteúdo e informação constantes do Website,
                  comprometendo-se a não utilizar qualquer dispositivo software
                  ou rotina apta a afetar ou a tentar interferir com a
                  disponibilização e o acesso público e/ou o correto
                  funcionamento do Website, sob pena de ser civil e
                  criminalmente responsável por tais atos.
                </li>
                <li>
                  A disponibilização do Website poderá ser suspensa ou
                  interrompida, temporária ou definitivamente, em qualquer
                  altura e sem qualquer aviso prévio, designadamente para
                  manutenção, sem que essa suspensão ou interrupção possa servir
                  como fundamento para qualquer pretensão indemnizatória, seja a
                  que título for, para qualquer Utilizador.
                </li>
              </ol>
            </li>
            <li>
              <h2>Propriedade intelectual e direito à imagem</h2>
              <ol>
                <li>
                  Os conteúdos, incluindo mas não se limitando aos ícones,
                  imagens, gráficos, texto, fotografias e funcionalidades
                  existentes no Website estão protegidos por direitos de
                  propriedade intelectual e/ou por direitos de personalidade. Os
                  referidos conteúdos sujeitos aos direitos de autor,
                  propriedade industrial, nomes de domínio e/ou direito de
                  personalidade são da exclusiva titularidade da Empresa.
                </li>
                <li>
                  Ao aceder ao Site, o Utilizador concorda em não reproduzir,
                  alterar, copiar, modificar ou divulgar publicamente qualquer
                  conteúdo deste Site e a não inserir ou implementar qualquer
                  link para este Website em qualquer website propriedade.
                </li>
              </ol>
            </li>
            <li>
              <h2>Limitação de responsabilidade</h2>
              <ol>
                <li>
                  É preocupação da Empresa que o Website esteja do agrado e
                  auxilie o Utilizador. Existem, contudo, algumas garantias que
                  não poderão ser assumidas.
                </li>

                <li>
                  A informação disponibilizada no Website é fornecida “como
                  está”, sem alguma garantia ou condição, expressa ou implícita,
                  quanto ao conteúdo do Website ou à precisão ou confiabilidade
                  de qualquer informação ou declarações nele contidas. A Empresa
                  fará todos os esforços ao seu alcance por forma a incluir
                  informação precisa e atual, sempre que possível.
                </li>
              </ol>
            </li>
            <li>
              <h2>Sugestões e comentários</h2>
              <p>
                Se o Utilizador pretender fazer qualquer sugestão ou comentário
                relativamente ao Website ou se tiver alguma dúvida que pretende
                esclarecer, pedimos que entre em contato com a Empresa através
                de um dos contactos fornecidos acima.
              </p>
            </li>
            <li>
              <h2>Invalidade</h2>
              <p>
                Se algum dos Termos e Condições estiver inválido mesmo que
                apenas parcialmente, não prejudicará os restantes, que
                continuarão a ser considerados válidos e em vigor de forma
                plena; relativamente à parte afetada, será regida pelas normas
                supletivas aplicáveis, com recurso, se assim o for preciso, às
                regras de integração dos negócios jurídicos.
              </p>
            </li>
            <li>
              <h2>Alterações aos termos e condições</h2>
              <ol>
                <li>
                  A Empresa reserva-se no direito de introduzir, em qualquer
                  altura, alterações aos Termos e Condições, sendo que as ditas
                  alterações consideradas relevantes devem ser notificadas ao
                  Utilizador através do próprio Website, através de avisos
                  específicos publicados no Site e previamente à solicitação de
                  consentimento do Utilizador àquelas alterações. O Utilizador
                  deve consultar os Termos e Condições regularmente.
                </li>

                <li>
                  Se o Utilizador não aceitar e não concordar com os Termos e
                  Condições alterados, deve pôr termo ao uso do Website.
                </li>

                <li>
                  Em caso de conflito entre os presentes Termos e Condições e os
                  publicados posteriormente devido a alterações, predominam
                  estes sobre aqueles.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </TermsWrapper>
  );
};

export default Terms;
