import styled from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";

const NavWrapper = styled.div`
  display: flex;
  gap: 2rem;
  /* text-transform: uppercase; */
  font-weight: 700;
  white-space: nowrap;

  .nav-link {
    transition: all 0.2s;
    color: ${colors.primary_2};

    &:hover {
      color: ${colors.primary};
    }

    ${screens.lg} {
      font-weight: 600;
      /* justify-items: center; */
    }
  }
  .nav-link.active {
    color: ${colors.primary_2};
  }
  ${screens.md} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    /* justify-items: center; */
  }
`;
export { NavWrapper };
