import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { FooterBottom, FooterContainer, FooterWrapper } from "./footer.style";
import { ThemeContext } from "_context/themeContext";
import { Copyright, Menu, SocialLinks } from "_components/atoms";
import lightLogo from "_assets/img/logo-white.png";
import darkLogo from "_assets/img/logo-black.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <FooterContainer>
        <div className="info-container">
          <div className="logo">
            <Link className="logo" to="/teste">
              <img
                src={isDarkTheme ? lightLogo : darkLogo}
                alt={isDarkTheme ? "Dark Mode" : "Light Mode"}
              />
            </Link>
          </div>
        </div>
        <div className="info-container">
          <h3>{t("Footer.title_1")}</h3>
          <Link className="phone" to="tel:911 140 301" target="_blank">
            <span>911 140 301*</span>
            <p>{t("Footer.costs")}</p>
          </Link>
          <div className="time-info">
            <ul>
              <li>{t("Footer.schedule_1")}</li>
              <li>{t("Footer.schedule_2")}</li>
              <li>{t("Footer.schedule_3")}</li>
            </ul>
          </div>

          <Link to="/contacts" className="contacts-link">
            <p>{t("Footer.contacts")}</p>
          </Link>
        </div>
        <div className="info-container">
          <h3>{t("Footer.title_2")}</h3>
          <div className="footer-nav">
            <Menu className="menu" />
          </div>
        </div>
        <div className="info-container social">
          <h3>{t("Footer.title_3")}</h3>
          <div className="social-container">
            <SocialLinks />{" "}
            <div className="app-livro">
              <Link
                className="livro-reclamacao"
                to="https://www.livroreclamacoes.pt/Inicio/"
              >
                <img
                  src={require("_assets/img/livroreclamacoes-light.png")}
                  alt="Médicos na Manga Logo"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="info-container"></div>
      </FooterContainer>
      <FooterBottom>
        <Copyright />
      </FooterBottom>
    </FooterWrapper>
  );
};

export default Footer;
