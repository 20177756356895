import React from "react";
import { NavWrapper } from "./menu.style";
import {  NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";


const navigation = [
  // { name: "Nav.about", href: "/about" },
  // { name: "Nav.dicas", href: "/dicas" },
  { name: "Nav.services", href: "/services" },
  // { name: "Nav.staff", href: "/staff" },
  { name: "Nav.contacts", href: "/contacts" },
  
];

const Menu = (props) => {
  const { open, setOpen } = props;
  const handleCloseMenu = () => {
    // setOpen(false);
  };
  const { t } = useTranslation();
  return (
    <NavWrapper open={open}>
      {navigation.map((item) => (
        <NavLink key={item.name} to={item.href} onClick={handleCloseMenu}  className="nav-link"
        activeClassName="active" > 
          {t(item.name)}
        </NavLink>
      ))}
    </NavWrapper>
  );
};

export default Menu;
