import styled from 'styled-components';
import { colors } from '_theme/colors';
import { screens } from '_theme/screens';

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.background_emphasis};
  /* box-shadow: 0 0px 6px ${colors.light_border}; */
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  height: 100vh;
  /* width: 10rem; */
  position: fixed;
  text-align: left;
  padding: 5rem 4rem 2rem 1.5rem;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 3;
  align-items: center;

  .linkstyle {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 1rem 0;
    color: ${colors.blue};
    transition: color 0.3s linear;
    margin: 0;
    transition: all 0.2s;
    font-weight: 700;
    
    &:hover {
      /* transform: scale(1.03); */
      color: ${colors.primary};
      background-color: ${colors.light_background_emphasis};
    }
  }

  ${screens.lg} {
    
  .linkstyle{
    text-align: center;
  }
  width: fit-content;
  padding: 5rem 2rem;
  /* transform: ${({ open }) => (open ? 'translatey(0)' : 'translatey(-100%)')}; */
  }
  .menu-button{
    margin: 1rem auto;
    width: fit-content;
  }

`;

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); // Semi-transparência para a sobreposição

  z-index: 2; // Certifique-se que está abaixo do menu
`;

export { StyledMenu, Overlay };