import React from "react";
import { CopyrightWrapper } from "./copyright.style";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SocialLinks from "../social-links/social-links";
import Button from "../buttons/button/button";

const Copyright = () => {
  const navigation = [
    { name: "Footer.cookies", href: "/cookies" },
    { name: "Footer.terms", href: "/terms" },
    { name: "Footer.rgpd", href: "/rgpd" },
  ];
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <CopyrightWrapper>
      <div className="production">
      <span>© Mínima - Médicos & Arte lda. {currentYear}</span>  
      <span>ERS - E165832</span>
      </div>
      <div className="navigation">
        {navigation.map((item) => (
          <Link className="linkstyle" key={item.name} to={item.href}>
            {t(item.name)}
          </Link>
        ))}
      </div>
    </CopyrightWrapper>
  );
};

export default Copyright;
