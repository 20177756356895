import styled from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import banner from "_assets/img/banners/BANNER-Homepage-02.png";
import background2 from "_assets/img/graphics/a.svg";

const ServicesWrapper = styled.div`
  background: ${({ theme }) => theme.background};
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  margin: 0 auto;
 
  
  .services-banner {
    background-image: url(${banner});
    width: 100%;
    height: 13rem;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;

    /* margin-top: -4rem; */

    h1 {
      font-size: 4rem;
      color: ${colors.white};
      text-transform: uppercase;
      margin: 0 0 0 10%;
    }
  }

  .service-container{
    background-image: url(${background2});
    margin: 0 auto;
    background-position: center; // Centraliza a imagem de fundo
    
    p{
      padding-top: 1rem;
    text-align: justify;
    line-height: 1.5;
    text-transform: uppercase;
    font-weight: bold;
  }

  .grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, 1fr));
  gap: 10px; /* Space between rows and columns */
  padding:48px 20px; /* Optional: Adds some padding around the grid */
  align-items: baseline;

  ${screens.lg} {
    display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.grid-item {
  padding: 10px; /* Optional: Adds some padding inside each grid item */
  text-align: center; /* Optional: Centers the content inside the grid item */

  p{
    text-align: center;
  }
}

    ${screens.lg} {
      padding: 2rem;
    }
  }

  .info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem 0;
    background: ${({ theme }) => theme.background_emphasis};

 

    .item {
      text-align: center;
      flex-basis: 200px;
      align-items: center;
      font-weight: 700;

      p {
        font-weight: 400;
      }
    }
  }
  .middle-container {
    display: flex;
    padding: 0 9.625rem;
    ${screens.lg} {
      padding: 0;
    }

    .container-left,
    .container-right {
      flex: 1;
      /* max-height: 500px; */
    }

    .container-left {
      background-color: grey;
      background-size: cover;
      background-position: center;
      ${screens.lg} {
        display: none;
      }
    }

    .container-right {
      text-align: center;
      padding: 4rem 2rem;
      max-width: 600px;
      margin: 0 auto;
      ${screens.lg} {
      padding: 0;
    }
    }
  }

  .bottom-container {
    display: flex;
    padding: 0 9.625rem;
    ${screens.lg} {
      padding: 0;
    }

    .container-left,
    .container-right {
      flex: 1;
      /* max-height: 500px; */
    }

    .container-right {
      background-color: grey;
      background-size: cover;
      background-position: center;
      ${screens.lg} {
        display: none;
      }
    }

    .container-left {
      text-align: center;
      padding: 4rem 2rem;
      max-width: 600px;
      margin: 0 auto;
      ${screens.lg} {
      padding: 0;
    }
    }
  }

  .middle-container,
  .bottom-container {
    margin: 4rem 0;

    ${screens.lg} {
      margin: 0;
    }
  }
`;

export { ServicesWrapper };
