import React, { useContext } from "react";

import lightModeIcon from "_assets/img/icons/light-mode.svg";
import darkModeIcon from "_assets/img/icons/dark-mode.svg";
import { ThemeContext } from "_context/themeContext.js";
import { SwitchWrapper } from "./switch.style";

function Switch() {
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);

  return (
    <SwitchWrapper onClick={toggleTheme}>
      <img
        src={isDarkTheme ? darkModeIcon : lightModeIcon}
        alt={isDarkTheme ? "Dark Mode" : "Light Mode"}
      />
      <p>{isDarkTheme ? "Dark" : "Light"} Mode</p>
    </SwitchWrapper>
  );
}

export default Switch;
