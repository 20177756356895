import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const FooterWrapper = styled.div`
  background: ${({ theme }) => theme.background_emphasis};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
`;

const FooterContainer = styled.div`
  /* margin: 0 auto; */
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  width: 100%;
  max-width: 1920px;
  position: relative; // Adicione esta linha para a div pai~

  ${screens.lg} {
    height: auto;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
  }

  /* ${screens.md} {
    height: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2rem;
    padding: 1rem;
  } */

  .info-container {
    display: flex;
    flex-direction: column;
    max-width: 300px;

    ${screens.lg} {
      width: 100%;
      gap: 1rem;

      h3 {
        color: ${colors.primary_2};
      }
    }

    .phone {
      font-size: 2rem;
      font-weight: 400;
      margin-top: 0.5rem;
      span {
        color: ${colors.primary_2};
      }
      :hover {
        color: ${colors.primary};
      }

      p {
        font-size: small;
        line-height: 1.5rem;
      }
    }

    .time-info {
      margin-top: 0.5rem;
      line-height: 1.5rem;
    }
    .contacts-link {
      font-weight: 600;
      margin-top: 1rem;

      p {
        color: ${colors.primary_2};
      }

      :hover {
        color: ${colors.primary};
      }
    }
  }

  .footer-nav {
    margin-top: 0.5rem;
  }

  .social-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 0.5rem;
    gap: 1rem;

    .app-button {
      display: none;
      ${screens.ssm} {
        display: block;
        margin-top: 1rem;
      }
    }

    ${screens.ssm} {
      /* margin: 0 auto; */
    }
  }

  /* .middle-container {
    margin: 0 0 0 4rem;
    width: 100%;

    ${screens.lg} {
      margin: 0;
    }

    .footer-nav {
      display: flex;
      flex-direction: column;

      gap: 1.5rem;
      padding-top: 0.5rem;
      padding-bottom: 1rem;

      ${screens.lg} {
        gap: 1rem;
      }
    }
    .app-livro {
      display: flex;
      justify-content: end;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      ${screens.lg} {
        display: none;
      }

      .livro-reclamacao {
        text-align: right;
        img {
          filter: ${({ theme }) => theme.filter};
        }
      }
    }
    .button {
      text-transform: uppercase;
      display: block;
    }
  } */
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  max-width: 1920px;
  margin: 1rem auto;

  ${screens.lg} {
    font-size: xx-small;
  }
`;
export { FooterContainer, FooterWrapper, FooterBottom };
