import { screens } from "_theme/screens";
import styled from "styled-components";

export const MapsWrapper=styled.div`
text-align: center;
img{
    width: 50%;
}


`